

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Typogrphy */
.text-gold {
  color: #FFD700;
}

#content.show {
  display: block;
}



/* Default theme */

.default {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  color: #000000;
}

/* Dark theme */
.dark {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity)); /* Tailwind's bg-gray-900 */
  color: #FFFFFF;
}

/* Light theme */
.light {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity)); /* Tailwind's bg-gray-50 */
  color: #000000;
}


/* src/styles/styles.css */
body {
  font-family: Arial, sans-serif; /* Optional: set a default font family */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.slick-prev:before,
.slick-next:before {
  color: black;
}

.slick-dots li button:before {
  color: black;
}

.slick-dots li.slick-active button:before {
  color: black;
}

.hero-carousel .carousel .slide {
  background: none;
}


  .see-details-popup {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 80;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.75);
  }


  /* src/index.css or your main CSS file */
.form-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: auto;
}

input,
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

@media (max-width: 767px) {
  .form-container {
    grid-template-columns: 1fr !important;
  }

  .form-container input,
  .form-container select,
  .form-container button {
    width: 100%;
    margin-bottom: 10px;
  }
}

/* Embedded Website*/
/* src/styles/styles.css */
.embedded-iframe {
  width: 100%;
  height: 400px; /* Adjust height as needed */
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* modal style */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  width: 90%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

/* Carousel destination card */
.slick-center .destination-card {
  transform: scale(1.1);
  transition: transform 0.3s ease;
  z-index: 100;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
}

