.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .plane {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 10%;
    animation: fly 3s ease-in-out forwards;
  }
  
  @keyframes fly {
    0% {
      transform: translate(0, 0) scale(1);
    }
    100% {
      transform: translate(100vw, -100vh) scale(8.0);
    }
  }
  